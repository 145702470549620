@media screen and (max-width: 575.98px) {
    footer {
        .links li {
            &:not(:last-of-type) {
                margin-inline-end: 5px !important;
            }
        }
    }
}

@media screen and (max-width: 991.98px) {
    .nav_bar {
        .side_menu {
            top: 0;
            bottom: 0;
            width: 100%;
            right: -100%;
            z-index: 100;
            padding: 24px;
            max-width: 300px;
            position: fixed;
            // vars
            background-color: $white;
            // mixin
            @include prefix(border-radius, 15px 0 0 15px);
            @include prefix(transition, right .2s linear);
            @include prefix(box-shadow, 8px 0px 10px $black);
            .links {
                margin-top: 40px;
                li {
                    margin-inline-end: 0px;
                    &:not(:last-of-type) {
                        margin-bottom: 20px;
                    }
                    &:hover, &.active {
                        a {

                            // vars
                            color: $white;
                            background-color: $mainColor;
                            // mixin
                            @include prefix(border-radius, 5px);
                       }
                    }
                    a {
                        display: block;
                        padding-inline-end: 20px;
                        padding-inline-start: 20px;                        
                    }
                }
            }
            .pages_links {
                margin-top: 30px;
                a {
                    display: block;
                    &:first-of-type {
                        margin-inline-end: 0px;
                    }
                    .sign_in {
                        // vars
                        border: 1px solid $mainColor;
                    }
                }
            }
        }
    }
}