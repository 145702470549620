// colors
$white: #ffffff;
$black: #000000;
$green: #12B118;
$mainColor: #F79200;
$secondColor: #000000;
$mainText: #1F1F1F;
$text: #1F2934;
$subText: #5D5D5D;
$subMediumText: #888888;
$subLightText: #B0B0B0;
$mainBg: #FAFAFA;
$mainMediumBg: #F5F5F5;
$border_1: #E7E7E7;
$border_2: #F0F0F0;


// fonts
$mainFont: MontserratArabic;

