/* // import globle types & style fonts */
@font-face {
    font-family: 'MontserratArabic';
    src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-Thin.otf") format('opentype');
    font-weight: 100; /* Thin */
}

@font-face {
    font-family: 'MontserratArabic';
    src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-ExtraLight.otf") format('opentype');
    font-weight: 200; /* ExtraLight */
}

@font-face {
    font-family: 'MontserratArabic';
    src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-Light.otf") format('opentype');
    font-weight: 300; /* Light */
}

@font-face {
    font-family: 'MontserratArabic';
    src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-Regular.otf") format('opentype');
    font-weight: 400; /* Regular */
}

@font-face {
    font-family: 'MontserratArabic';
    src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-Medium.otf") format('opentype');
    font-weight: 500; /* Medium */
}

@font-face {
    font-family: 'MontserratArabic';
    src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-SemiBold.otf") format('opentype');
    font-weight: 600; /* SemiBold */
}

@font-face {
    font-family: 'MontserratArabic';
    src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-Bold.otf") format('opentype');
    font-weight: 700; /* Bold */
}

@font-face {
    font-family: 'MontserratArabic';
    src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-ExtraBold.otf") format('opentype');
    font-weight: 800; /* ExtraBold */
}

@font-face {
    font-family: 'MontserratArabic';
    src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-Black.otf") format('opentype');
    font-weight: 900; /* Black */
}