.app_btn {
    z-index: 1;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    min-height: 48px;
    line-height: 24px;
    position: relative;
    padding: 12px 24px;
    border: 1px solid currentColor;
    // vars
    color: $white !important;
    background-color: $mainColor !important;
    &::before {
        top: 0;
        right: 0;
        left: 100%;
        z-index: -1;
        content: '';
        height: 100%;
        position: absolute;
        background-color: rgba($white, 15%);
        @include prefix(transition, all .1s linear);
    }
    &:hover {
        &::before {
            left: 0px;
        }
    }
    &.ltr {
        direction: ltr;
    }
    &.rtl {
        direction: rtl;
    }
    .wrap_svg_icon {
        margin-inline-end: 4px;
    }
}