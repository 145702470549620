.input_field {
    position: relative;
    &.has_icon {
        input {
            padding-inline-end: 8px !important;
            padding-inline-start: 44px !important;
        }
    }
    &.is_valid {
        input {
            // vars
            border-color: $mainColor !important;
        }
        .wrap_icon svg, .wrap_icon svg  path{
            stroke: $mainColor !important;
        }
        .wrap_input_file label {
            border-color: $mainColor !important;
            .upload_img_file path {
                stroke: $mainColor;
            }
            .label_text {
                color: $mainColor;
            }
        }
    }
    &.in_valid {
        input {
            // vars
            border-color: var(--bs-danger) !important;
        }
        .wrap_icon svg, .wrap_icon svg  path {
            stroke: var(--bs-danger) !important;
        }
    }
    label {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 12px;
        letter-spacing: -0.25px;
        // vars
        color: $text;
    }
    input {
        direction: rtl;
        &:not([type=file]) {
            width: 100%;
            padding: 14px;
            font-size: 12px;
            font-weight: 300;
            min-height: 48px;
            text-align: start;
            border-radius: 8px;
            line-height: 24px;
            letter-spacing: -0.25px;
            // vars
            color: $mainText;
            background-color: $white;
            border: 1px solid $border_1;
        }
        &::placeholder {
            color: $subLightText;
        }
        &:focus-visible {
            outline: none !important;
        }

    }
    #phoneNumber {
        border-radius: 0px 8px 8px 0px;
    }
    .wrap_input_file {
        position: relative;
        input {
            inset: 0;
            opacity: 0;
        }
        label {
            width: 100%;
            height: 112px;
            padding: 10px;
            overflow: hidden;
            position: relative;
            border-radius: 8px;
            text-align: center;
            // vars
            background-color: $mainBg;
            border: 1px dashed $border_1;
            .label_text {
                width: 90%;
                margin-top: 8px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 12px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.25px;
                // vars
                color: $mainText;
            }
        }
    }
    .wrap_icon {
        top: 50%;
        right: 16px;
        position: absolute;
        line-height: normal;
        transform: translate(0, -50%);
    }
    .error_msg {
        padding-inline-start: 16px;
    }
}
