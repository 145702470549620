.contact_us {
    padding: 80px 0;
    background-color: #010101;
    .wrap_start {
        ul li a {
            // vaars
            background-color: rgba($white, 8%);
        }
    }
    .wrap_end {
        padding: 60px;
        background: url(/src/assets//img/contact_us_bg.png) no-repeat left center rgba($white, 4%);
        background-size: cover;
        form {
            input, textarea, select {
                width: 100%;
                border: none;
                padding: 12px 16px;
                // vars
                color: $white;
                background-color: rgba($white, 10%);
                &::placeholder {
                    color: $subLightText;
                }
            }
            select option {
                font-size: 16px;
                color: $mainText;
            }
            textarea {
                min-height: 110px;
            }
            .app_btn {
                // vars
                border-color: $mainColor;
            }
        }
    }
}