.page {
    .page_content {
        margin: 80px auto;
        max-width: 992px;
        p {
            font-size: 16px;
            font-weight: 300;
            margin-bottom: 42px;
            line-height: 32px; /* 200% */
            // var
            color: $subText !important;
        }
    }
}

// colors
.main_color {
    color: $mainColor !important;
}

.second_color {
    color: $secondColor !important;
}

.main_color_text {
    color: $mainText !important;
}
.color_green {
    color: $green !important;
}
.color_text {
    color: $text !important;
}
.sub_color_text {
    color: $subText !important;
}

.sub_color_md_text {
    color: $subMediumText !important;
}

.sub_color_light_text {
    color: $subLightText !important;
}

.main_bg {
    background-color: $mainBg !important;
}

.main_md_bg {
    background-color: $mainMediumBg !important;
}

.border_color_1 {
    color: $border_1 !important;
}

.border_color_2 {
    color: $border_2 !important;
}

.border_1 {
    border: 1px solid $border_1;
}

.border_2 {
    border: 1px solid $border_2;
}

//
.line_h_8 {
    line-height: 8px; /* 66.667% */
}
//
.line_h_12 {
    line-height: 12px; /* 85.714% */
}
//
.line_h_16 {
    line-height: 16px; /* 114.286% */
}
//
.line_h_20 {
    line-height: 20px; /* 62.5% */    
}
//
.line_h_24 {
    line-height: 24px; /* 171.429% */
}
//
.line_h_32 {
    line-height: 32px; /* 200% */
}
.line_h_56 {
    line-height: 56px; /* 175% */
}

//
.letter_s_m_25 {
    letter-spacing: -0.25px;
}
//
.custom_container {
    width: 100%;
    margin: 0 auto;
    padding: 0 12px;
    max-width: 1240px;
    white-space: normal;
}
//
.dir_rtl {
    direction: rtl !important;
}

.dir_ltr {
    direction: ltr !important;
}

//
.line_h_default {
    line-height: 22px
}

.line_h_2 {
    line-height: 26px
}

//
.orders_area {
    overflow-y: auto;
    height: calc(100vh - 300px);
}

//
.pointer {
    cursor: pointer;
}

// cutome_scroll
.custom_scroll {
    // padding-inline-end: 1px !important;
    &::-webkit-scrollbar {
        width: 5px;
        margin: 15px 0 ;
    }
       
    &::-webkit-scrollbar-track {
        margin: 15px 0 ;
        border-radius: 100px;
        background-color: rgb(219, 217, 217);
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-color: $mainColor,
    }     
}


// margin & padding
@for $i from 1 through 12 {
    @if $i == 1 {
        // margin
        .margin_full {
            margin: $i * 4 + px !important;
        }
        .margin_start {
            margin-inline-start: $i * 4 + px !important;
        }
        .margin_end {
            margin-inline-end: $i * 4 + px !important;
        }
        .margin_top {
            margin-block-start: $i * 4 + px !important;
        }
        .margin_bottom {
            margin-block-end: $i * 4 + px !important;
        }
        .margin_x {
            margin-inline-end: $i * 4 + px !important;
            margin-inline-start: $i * 4 + px !important;
        }  
        .margin_y {
            margin-block-end: $i * 4 + px !important;
            margin-block-start: $i * 4 + px !important;
        }

        // padding
        .padding_full {
            padding: $i * 4 + px !important;
        }
        .padding_start {
            padding-inline-start: $i * 4 + px !important;
        }
        .padding_end {
            padding-inline-end: $i * 4 + px !important;
        }
        .padding_top {
            padding-block-start: $i * 4 + px !important;
        }
        .padding_bottom {
            padding-block-end: $i * 4 + px !important;
        }
        .padding_x {
            padding-inline-end: $i * 4 + px !important;
            padding-inline-start: $i * 4 + px !important;
        }  
        .padding_y {
            padding-block-end: $i * 4 + px !important;
            padding-block-start: $i * 4 + px !important;
        }        

        // border-radius
        .border_radius {
            @include prefix(border-radius, $i * 4 + px);
        }
    }
    @else {

        // margin
        .margin_full_#{$i} {
            margin: $i * 4 + px !important
        }        
        .margin_start_#{$i} {
            margin-inline-start: $i * 4 + px !important;
        }
        .margin_end_#{$i} {
            margin-inline-end: $i * 4 + px !important;
        }
        .margin_top_#{$i} {
            margin-block-start: $i * 4 + px !important;
        }
        .margin_bottom_#{$i} {
            margin-block-end: $i * 4 + px !important;
        }
        .margin_x_#{$i} {
            margin-inline-end: $i * 4 + px !important;
            margin-inline-start: $i * 4 + px !important;
        }  
        .margin_y_#{$i} {
            margin-block-end: $i * 4 + px !important;
            margin-block-start: $i * 4 + px !important;
        }        

        // padding
        .padding_full_#{$i} {
            padding: $i * 4 + px !important; 
        }          
        .padding_start_#{$i} {
            padding-inline-start: $i * 4 + px !important; 
        }
        .padding_end_#{$i} {
            padding-inline-end: $i * 4 + px !important; 
        }
        .padding_top_#{$i} {
            padding-block-start: $i * 4 + px;
        }
        .padding_bottom_#{$i} {
            padding-block-end: $i * 4 + px !important; 
        } 
        .padding_x_#{$i} {
            padding-inline-end: $i * 4 + px !important; 
            padding-inline-start: $i * 4 + px !important; 
        }  
        .padding_y_#{$i} {
            padding-block-end: $i * 4 + px !important; 
            padding-block-start: $i * 4 + px !important; 
        }

        // radius
        .border_radius_#{$i} {
            @include prefix(border-radius, $i * 4 + px);
        }
    }
}

// font family
.font {
    font-family: $mainFont !important;
}

// font-size
$def_size: 12;
@for $size from 1 through 12 {
    .font_#{$def_size} {
        font-size: $def_size + px !important;
    }    
    $def_size: $def_size + 2;
}

// font-weight
@for $weight from 1 through 9 {
    .font_#{$weight * 100} {
        font-weight: $weight * 100 !important;
    }
}