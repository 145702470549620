.register_trader_page {
    min-height: 100vh;
    .header_content {
        padding-top: 22px;
        width: calc(100% - 490px);
    }
    .register_content {
        width: calc(100% - 490px);
        .custom_container {
            // padding: 22px 12px;
            padding-bottom: 65px;
        }
        .stepper {
            padding: 11px 12px;
            // vars
            background-color: $white;
            // mixin
            @include prefix(box-shadow, 0px 5px 24px 0px rgba(150, 150, 150, 8%));
            ul {
                margin: 0 auto;
                position: relative;
                width: calc(100% - 115px);
                // &::after {
                //     content: '';
                //     right: 50%;
                //     width: 100%;
                //     height: 1px;
                //     max-width: 178px;
                //     position: absolute;
                //     transform: translate(50%, 0);                    
                //     // vars
                //     background-color: $border_1;
                // }                
                li {
                    opacity: 0.5;
                    padding: 11px 0;
                    @include prefix(transition, opacity .1s linear);
                    &.active {
                        opacity: 1;
                    }
                    .wrap_start {
                        width: 40px;
                        height: 40px;
                        background-color: rgba($green, 8%);
                    }
                }
            }
        }
        .stepper_content {
            .step {
                display: none;
                &.active {
                    display: block !important;
                }
                .wrap_form {
                    padding: 40px 50px;
                    // vars
                    background-color: $white;
                    border: 1px solid $border_1;
                    .wrap_radio {
                        input[type=radio]:checked + label {
                            // vars
                            color: $white;
                            background-color: $mainColor;
                        }
                        label {
                            cursor: pointer;
                            font-size: 14px;
                            font-weight: 500;
                            min-width: 74px;
                            min-height: 40px;
                            padding: 8px 10px;
                            line-height: 24px;
                            text-align: center;
                            letter-spacing: -0.25px;
                            // vars
                            color: $subMediumText;
                            background-color: $border_2;
                            // mixin
                            @include prefix(border-radius, 8px);                   
                        }
                    }
                    .question {
                        // display: none;
                    }
                }
                .wrap_stepper_btns {
                    .app_btn {
                        width: auto;
                        &.previous {
                            border-color: transparent !important;
                            background-color: transparent !important;
                            // vars
                            color: $subMediumText !important;
                        }
                    }
                }
            }
        }
    }
    .panner {
        width: 100%;
        padding: 40px;
        max-width: 490px;
        min-height: 100vh;
        margin-top: -108px;
        // vars
        background: url(/src/assets//img/stai_arrow_left_x1.svg) no-repeat bottom right $mainColor;
        background-size: auto;
        .wrap_content {
            height: 50%;
            max-width: 362px;
        }
    }
    .modal {
        .wrap_content {
            width: 100%;
            max-width: 502px;
            padding: 64px 15px;
            // vars
            background-color: $white;
            // mixin
            @include prefix(transition, all .2s linear);
            .wrap_msg{
                width: 100%;
                max-width: 456px;
            }
            .app_btn {
                max-width: 366px;
            }
        }
    }
}