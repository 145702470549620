@media screen and (max-width: 575.98px) {
    .home_page, .shipment_tracking_page {
        .wrap_header .shipment_tracking {
            padding: 18px 10px;
            .wrap_right {
                img {
                    width: 20px;                    
                    height: auto;
                }
                input {
                    font-size: 14px !important;
                }
            }
            .app_btn {
                max-width: none !important;
            }
        }
        &.home_page {
            .join_us {
                .wrap {
                    padding: 50px 12px;
                    &::before, &::after {
                        width: 100px;
                        height: 100px;
                        background-size: contain;
                    }
                    .wrap_content {
                        max-width: 415px;
                        h2 {
                            font-size: 24px !important;
                        }                        
                    }
                }
            }
        }
    }
    .register_trader_page {
        .stepper ul {
            width: auto !important;
        }
        .stepper_content .wrap_form {
            padding:  40px 15px !important;
        }
    }
}

@media screen and (max-width: 991.98px) {
    .home_page {
        .about_us .wrap_left {
            width: 100%;
            text-align: end;
        }
    }
    .shipment_tracking_page {
        .wrap_shipment_tracking_details {
            padding: 50px 12px !important;
            .wrap_box_details {
                max-width: none !important;
                h4 {
                    width: 100%;
                    text-align: center;
                }
                h4, ul {
                    padding: 30px 12px !important;
                }
            }
        }
    }
    .register_trader_page {
        .header_content {
            padding-bottom: 22px;
            width: 100%;
            .wrap-top {
                margin-block-end: 0px !important
            }
        }
        .register_content {
            width: 100%;
            max-width: none !important;
        }
        .panner {
            width: 100%;
            margin-top: 0;
            text-align: center;
            margin-block-end: 36px;
            max-width: none !important;
            min-height: auto !important;
            background-size: contain !important;
            .wrap_content {
                margin: 0 auto;
                max-width: none !important;
                height: auto !important;
            }
        }
    }
}