.home_page {
    .wrap_header {
        width: 100%;
        margin: 0 auto;
        max-width: 643px;
        h1 {
            margin-top: 80px;
        }
        .shipment_tracking {
            padding: 18px 25px;
            border-radius: 8px;
            // vars
            background-color: $mainBg;
            input {
                border: none;
                outline: none;
                padding: 10px 12px;
                background-color: transparent;
                &::placeholder {
                    // vars
                    color: $subMediumText;
                }
            }
            .app_btn {
                max-width: 108px;
            }
        }
    }
    .about_us {
        margin-bottom: 80px;
        .wrap_right {
            max-width: 567px;
            .app_btn {
                max-width: 113px;
            }
        }
    }
    .services {
        margin-bottom: 80px;
        .wrap_service {
            max-width: 340px;
        }
    }
    .join_us {
        margin-bottom: -210px;
        .wrap {
            padding: 80px 12px;
            position: relative;
            // vars
            background-color: $mainColor;
            // mixin
            @include prefix(border-radius, 24px);
            &::before {
                right: 0;
                top: -7px;                
                content: '';
                position: absolute;
                width: 265.73px;
                height: 255.61px;
                background: url(/src/assets//img/stai_arrow_right.svg) no-repeat top right;
            }
            &::after {
                left: 0;
                bottom: -7px;                
                content: '';
                position: absolute;
                width: 265.73px;
                height: 255.61px;
                background: url(/src/assets//img/stai_arrow_left.svg) no-repeat bottom left;
            }
        }
        .wrap_content {
            margin: 0 auto;
            max-width: 652px;
            .app_btn {
                max-width: 158px;
                // vars
                color: $mainColor !important;
                background-color: $white !important;
            }
        }
    }
    .contact_us {
        padding-top: 290px !important;
    }
}