footer {
    .wrap_top {
        padding-top: 56px;
        padding-bottom: 65px;
        .links li {
            font-size: 14px;
            font-weight: 300;
            @include prefix(transition, font-weight .1s linear);
            &:not(:last-of-type) {
                margin-inline-end: 20px;
            }
            &:hover, &.active {
                // font-weight: 500;
                a {
                    // vars
                    color: $mainColor;
                    text-shadow: 0px 0px 0px $mainColor;
                }
            }
            a {
                padding: 15px 10px;
                text-decoration: none;
                // vars
                color: $text;
                // mixin
                @include prefix(transition, all .1s linear);
            }
        }
        .media li {
            padding: 1px;
            border: 3px solid transparent;
            @include prefix(transition, border-color .1s linear);  
            &:hover {
                // vars
                border-color: $mainColor;
            }       
            a {
                width: 60px;
                height: 60px;
                // vars
                background-color: $mainColor;
            }
        }
    }
    .wrap_bottom {
        padding: 18px 0;
        // vars
        background-color: $mainBg;
    }
}