.shipment_tracking_page {
    .wrap_header {
        width: 100%;
        margin: 80px auto;
        max-width: 643px;
        .shipment_tracking {
            padding: 18px 25px;
            border-radius: 8px;
            // vars
            background-color: $mainBg;
            input {
                border: none;
                outline: none;
                padding: 10px 12px;
                background-color: transparent;
                &::placeholder {
                    // vars
                    color: $subMediumText;
                }
            }
            .app_btn {
                max-width: 108px;
            }
        }
    }
    .wrap_shipment_tracking_details {
        padding: 50px 70px;
        margin-bottom: 80px;
        border: 1px solid #F4F4F4;
        // vars
        background-color: $white;
        .wrap_top {
            margin: 0 auto;
            max-width: 425px;
        }
        .wrap_box_details {
            // vars
            border: 1px solid $border_2;
            h4 {
                padding: 19px 30px;
                // vars
                background-color: $mainMediumBg;
            }
            ul {
                padding: 30px;
            }
            &.shipment_info {
                width: 100%;
                max-width: 344px;
            }
            &.shipment_time_line {
                width: 100%;
                max-width: 549px;
                ul {
                    li {
                        z-index: 1;
                        position: relative;
                        &:last-of-type {
                            .wrap_progress::before {
                                top: 25px;
                                content: "";
                                width: 32px;
                                height: 100%;
                                z-index: -1;
                                position: absolute;
                                // vars
                                background-color: $white;
                            }
                        }
                        &.active {
                            .wrap_progress {
                                // // vars
                                &::after {
                                    background-color: $mainColor;
                                }
                                .progress_dot {
                                    // vars
                                    background-color: $mainColor;
                                }
                            }
                        }
                        .wrap_progress {
                            width: 32px;
                            height: 32px;
                            margin: 0 16px;
                            // position: relative;
                            border-radius: 60px;
                            // vars
                            background-color: $mainMediumBg;
                            &::after {
                                top: 25px;
                                width: 2px;
                                z-index: -2;
                                height: 100%;                               
                                content: '';
                                position: absolute;
                                // vars
                                background: $border_2;
                            }                         
                            .progress_dot {
                                width: 16px;
                                height: 16px;
                                border-radius: 60px;
                                // vars
                                border: 1px solid $white;
                                background-color: $subLightText;
                            }
                        }
                    }
                }
            }
        }
    }
}