.nav_bar {
    padding: 24px 0;
    top: -100px;
    @include prefix(transition, top .2s linear);
    &.fixed {
        top: 0;
        z-index: 10;
        width: 100%;
        position: fixed;
        background-color: $white;
        box-shadow: 0px 0px 5px rgba($black, 12%);
    }
    &.show {
        .navbar_toggle {
            span {
                &:nth-of-type(2) {
                    &::after, &::before {
                        opacity: 1;
                    }
                }
                &:not(:nth-of-type(2)) {
                    opacity: 0;
                }
            }
        }
        .side_menu {
            right: 0 !important;
        }
    }
    .navbar_toggle {
        height: 45px;
        padding: 10px 5px;
        border-radius: 5px;
        // vars
        border: 2px solid $mainColor;
        span {
            width: 40px;
            height: 3px;
            border-radius: 5px;
            // vars
            background-color: $mainColor;
            // mixin
            @include prefix(transition, all .1s linear);
            &:nth-of-type(2) {
                position: relative;
                &::after, &::before {
                    top: -4px;
                    right: 0px;
                    content: "";
                    opacity: 0;
                    height: 3px;
                    width: 20px;
                    display: block;
                    position: absolute;
                    border-radius: 5px;
                    // vars
                    background: $mainColor;
                    // mixin
                    @include prefix(transform, rotate(25deg));
                    @include prefix(transition, all .3s linear);
                }
                &::before {
                    right: 0;
                    top: auto;
                    bottom: -4px;
                    // mixin
                    @include prefix(transform, rotate(-25deg));
                }
            }
        }
    }
    .links li {
        font-size: 14px;
        font-weight: 300;
        @include prefix(transition, font-weight .1s linear);
        &:not(:last-of-type) {
            margin-inline-end: 20px;
        }
        &:hover, &.active {
            // font-weight: 500;
            a {
                // vars
                color: $mainColor;
                text-shadow: 0px 0px 0px $mainColor;
            }
        }
        a {
            padding: 15px 10px;
            text-decoration: none;
            // vars
            color: $text;
            // mixin
            @include prefix(transition, all .1s linear);
        }
    }
    .pages_links {
        a {
            &:first-of-type {
                margin-inline-end: 9px;
            }
            .sign_in {
                border: none;
                // vars
                color: $text !important;
                background-color: $white !important;
                &:hover {
                    color: $white !important;
                    background-color: $mainColor !important;
                }
            }
        }
    }
}