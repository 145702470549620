.loading_screen {
    inset: 0;
    padding: 15px;
    z-index: 1000;
    position: fixed;
    // var
    background-color: $white;
    .wrap_content {
        width: 100%;
        position: relative;
        overflow: hidden;
        max-width: 500px;
        min-height: 500px;
        animation: scaling 2s .7s infinite;
        .wrap_part_1 {
            position: relative;
            animation: comingFomLeft .5s linear;
        }
        .wrap_part_2 {
            position: relative;
            animation: comingFomRight .5s linear;
        }
    }
}


@keyframes comingFomLeft {
    0% {
        left: -100%;
    }
    100% {
        left: 0%;
    }
}

@keyframes comingFomRight {
    0% {
        right: -100%;
    }
    100% {
        right: 0%;
    }
}


@keyframes scaling {
    0%{
      scale: 1;
    }
    30% {
      scale: 1.3;
    }
    60% {
      scale: .5;
    }
    100% {
      scale: 1;
    }
  } 