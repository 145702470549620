.app_badge {
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    position: relative;
    padding: 10px 24px;
    border-radius: 40px;
    display: inline-block;
    // var
    color: $mainColor;
    font-family: $mainFont;
    &.default {
        font-size: 11px;
        font-weight: 400;
        padding: 8px 16px;
        line-height: normal;
    }
    &::after {
        inset: 0;
        content: '';
        z-index: 0;
        opacity: 8%;
        position: absolute;
        background-color: currentColor;
    }
}