@import 'variables/variables';

@import 'mixin/mixin';

@import 'helper/*';

@import 'components/*';

@import 'layout/navbar';

@import 'pages/*';

@import 'layout/contact_us';

@import 'layout/footer';

@import 'media-query/root';

@import 'layout/loading-screen';

@import '../fonts/font';

body {
    font-family: $mainFont;
    &.navbar_fixed {
        padding-top: 100px;
    }
}
