.page .page_content {
  margin: 80px auto;
  max-width: 992px;
}
.page .page_content p {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 42px;
  line-height: 32px; /* 200% */
  color: #5D5D5D !important;
}

.main_color {
  color: #F79200 !important;
}

.second_color {
  color: #000000 !important;
}

.main_color_text {
  color: #1F1F1F !important;
}

.color_green {
  color: #12B118 !important;
}

.color_text {
  color: #1F2934 !important;
}

.sub_color_text {
  color: #5D5D5D !important;
}

.sub_color_md_text {
  color: #888888 !important;
}

.sub_color_light_text {
  color: #B0B0B0 !important;
}

.main_bg {
  background-color: #FAFAFA !important;
}

.main_md_bg {
  background-color: #F5F5F5 !important;
}

.border_color_1 {
  color: #E7E7E7 !important;
}

.border_color_2 {
  color: #F0F0F0 !important;
}

.border_1 {
  border: 1px solid #E7E7E7;
}

.border_2 {
  border: 1px solid #F0F0F0;
}

.line_h_8 {
  line-height: 8px; /* 66.667% */
}

.line_h_12 {
  line-height: 12px; /* 85.714% */
}

.line_h_16 {
  line-height: 16px; /* 114.286% */
}

.line_h_20 {
  line-height: 20px; /* 62.5% */
}

.line_h_24 {
  line-height: 24px; /* 171.429% */
}

.line_h_32 {
  line-height: 32px; /* 200% */
}

.line_h_56 {
  line-height: 56px; /* 175% */
}

.letter_s_m_25 {
  letter-spacing: -0.25px;
}

.custom_container {
  width: 100%;
  margin: 0 auto;
  padding: 0 12px;
  max-width: 1240px;
  white-space: normal;
}

.dir_rtl {
  direction: rtl !important;
}

.dir_ltr {
  direction: ltr !important;
}

.line_h_default {
  line-height: 22px;
}

.line_h_2 {
  line-height: 26px;
}

.orders_area {
  overflow-y: auto;
  height: calc(100vh - 300px);
}

.pointer {
  cursor: pointer;
}

.custom_scroll::-webkit-scrollbar {
  width: 5px;
  margin: 15px 0;
}
.custom_scroll::-webkit-scrollbar-track {
  margin: 15px 0;
  border-radius: 100px;
  background-color: rgb(219, 217, 217);
}
.custom_scroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #F79200;
}

.margin_full {
  margin: 4px !important;
}

.margin_start {
  margin-inline-start: 4px !important;
}

.margin_end {
  margin-inline-end: 4px !important;
}

.margin_top {
  margin-block-start: 4px !important;
}

.margin_bottom {
  margin-block-end: 4px !important;
}

.margin_x {
  margin-inline-end: 4px !important;
  margin-inline-start: 4px !important;
}

.margin_y {
  margin-block-end: 4px !important;
  margin-block-start: 4px !important;
}

.padding_full {
  padding: 4px !important;
}

.padding_start {
  padding-inline-start: 4px !important;
}

.padding_end {
  padding-inline-end: 4px !important;
}

.padding_top {
  padding-block-start: 4px !important;
}

.padding_bottom {
  padding-block-end: 4px !important;
}

.padding_x {
  padding-inline-end: 4px !important;
  padding-inline-start: 4px !important;
}

.padding_y {
  padding-block-end: 4px !important;
  padding-block-start: 4px !important;
}

.border_radius {
  border-radius: 4px;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.margin_full_2 {
  margin: 8px !important;
}

.margin_start_2 {
  margin-inline-start: 8px !important;
}

.margin_end_2 {
  margin-inline-end: 8px !important;
}

.margin_top_2 {
  margin-block-start: 8px !important;
}

.margin_bottom_2 {
  margin-block-end: 8px !important;
}

.margin_x_2 {
  margin-inline-end: 8px !important;
  margin-inline-start: 8px !important;
}

.margin_y_2 {
  margin-block-end: 8px !important;
  margin-block-start: 8px !important;
}

.padding_full_2 {
  padding: 8px !important;
}

.padding_start_2 {
  padding-inline-start: 8px !important;
}

.padding_end_2 {
  padding-inline-end: 8px !important;
}

.padding_top_2 {
  padding-block-start: 8px;
}

.padding_bottom_2 {
  padding-block-end: 8px !important;
}

.padding_x_2 {
  padding-inline-end: 8px !important;
  padding-inline-start: 8px !important;
}

.padding_y_2 {
  padding-block-end: 8px !important;
  padding-block-start: 8px !important;
}

.border_radius_2 {
  border-radius: 8px;
  -o-border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.margin_full_3 {
  margin: 12px !important;
}

.margin_start_3 {
  margin-inline-start: 12px !important;
}

.margin_end_3 {
  margin-inline-end: 12px !important;
}

.margin_top_3 {
  margin-block-start: 12px !important;
}

.margin_bottom_3 {
  margin-block-end: 12px !important;
}

.margin_x_3 {
  margin-inline-end: 12px !important;
  margin-inline-start: 12px !important;
}

.margin_y_3 {
  margin-block-end: 12px !important;
  margin-block-start: 12px !important;
}

.padding_full_3 {
  padding: 12px !important;
}

.padding_start_3 {
  padding-inline-start: 12px !important;
}

.padding_end_3 {
  padding-inline-end: 12px !important;
}

.padding_top_3 {
  padding-block-start: 12px;
}

.padding_bottom_3 {
  padding-block-end: 12px !important;
}

.padding_x_3 {
  padding-inline-end: 12px !important;
  padding-inline-start: 12px !important;
}

.padding_y_3 {
  padding-block-end: 12px !important;
  padding-block-start: 12px !important;
}

.border_radius_3 {
  border-radius: 12px;
  -o-border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
}

.margin_full_4 {
  margin: 16px !important;
}

.margin_start_4 {
  margin-inline-start: 16px !important;
}

.margin_end_4 {
  margin-inline-end: 16px !important;
}

.margin_top_4 {
  margin-block-start: 16px !important;
}

.margin_bottom_4 {
  margin-block-end: 16px !important;
}

.margin_x_4 {
  margin-inline-end: 16px !important;
  margin-inline-start: 16px !important;
}

.margin_y_4 {
  margin-block-end: 16px !important;
  margin-block-start: 16px !important;
}

.padding_full_4 {
  padding: 16px !important;
}

.padding_start_4 {
  padding-inline-start: 16px !important;
}

.padding_end_4 {
  padding-inline-end: 16px !important;
}

.padding_top_4 {
  padding-block-start: 16px;
}

.padding_bottom_4 {
  padding-block-end: 16px !important;
}

.padding_x_4 {
  padding-inline-end: 16px !important;
  padding-inline-start: 16px !important;
}

.padding_y_4 {
  padding-block-end: 16px !important;
  padding-block-start: 16px !important;
}

.border_radius_4 {
  border-radius: 16px;
  -o-border-radius: 16px;
  -moz-border-radius: 16px;
  -webkit-border-radius: 16px;
}

.margin_full_5 {
  margin: 20px !important;
}

.margin_start_5 {
  margin-inline-start: 20px !important;
}

.margin_end_5 {
  margin-inline-end: 20px !important;
}

.margin_top_5 {
  margin-block-start: 20px !important;
}

.margin_bottom_5 {
  margin-block-end: 20px !important;
}

.margin_x_5 {
  margin-inline-end: 20px !important;
  margin-inline-start: 20px !important;
}

.margin_y_5 {
  margin-block-end: 20px !important;
  margin-block-start: 20px !important;
}

.padding_full_5 {
  padding: 20px !important;
}

.padding_start_5 {
  padding-inline-start: 20px !important;
}

.padding_end_5 {
  padding-inline-end: 20px !important;
}

.padding_top_5 {
  padding-block-start: 20px;
}

.padding_bottom_5 {
  padding-block-end: 20px !important;
}

.padding_x_5 {
  padding-inline-end: 20px !important;
  padding-inline-start: 20px !important;
}

.padding_y_5 {
  padding-block-end: 20px !important;
  padding-block-start: 20px !important;
}

.border_radius_5 {
  border-radius: 20px;
  -o-border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
}

.margin_full_6 {
  margin: 24px !important;
}

.margin_start_6 {
  margin-inline-start: 24px !important;
}

.margin_end_6 {
  margin-inline-end: 24px !important;
}

.margin_top_6 {
  margin-block-start: 24px !important;
}

.margin_bottom_6 {
  margin-block-end: 24px !important;
}

.margin_x_6 {
  margin-inline-end: 24px !important;
  margin-inline-start: 24px !important;
}

.margin_y_6 {
  margin-block-end: 24px !important;
  margin-block-start: 24px !important;
}

.padding_full_6 {
  padding: 24px !important;
}

.padding_start_6 {
  padding-inline-start: 24px !important;
}

.padding_end_6 {
  padding-inline-end: 24px !important;
}

.padding_top_6 {
  padding-block-start: 24px;
}

.padding_bottom_6 {
  padding-block-end: 24px !important;
}

.padding_x_6 {
  padding-inline-end: 24px !important;
  padding-inline-start: 24px !important;
}

.padding_y_6 {
  padding-block-end: 24px !important;
  padding-block-start: 24px !important;
}

.border_radius_6 {
  border-radius: 24px;
  -o-border-radius: 24px;
  -moz-border-radius: 24px;
  -webkit-border-radius: 24px;
}

.margin_full_7 {
  margin: 28px !important;
}

.margin_start_7 {
  margin-inline-start: 28px !important;
}

.margin_end_7 {
  margin-inline-end: 28px !important;
}

.margin_top_7 {
  margin-block-start: 28px !important;
}

.margin_bottom_7 {
  margin-block-end: 28px !important;
}

.margin_x_7 {
  margin-inline-end: 28px !important;
  margin-inline-start: 28px !important;
}

.margin_y_7 {
  margin-block-end: 28px !important;
  margin-block-start: 28px !important;
}

.padding_full_7 {
  padding: 28px !important;
}

.padding_start_7 {
  padding-inline-start: 28px !important;
}

.padding_end_7 {
  padding-inline-end: 28px !important;
}

.padding_top_7 {
  padding-block-start: 28px;
}

.padding_bottom_7 {
  padding-block-end: 28px !important;
}

.padding_x_7 {
  padding-inline-end: 28px !important;
  padding-inline-start: 28px !important;
}

.padding_y_7 {
  padding-block-end: 28px !important;
  padding-block-start: 28px !important;
}

.border_radius_7 {
  border-radius: 28px;
  -o-border-radius: 28px;
  -moz-border-radius: 28px;
  -webkit-border-radius: 28px;
}

.margin_full_8 {
  margin: 32px !important;
}

.margin_start_8 {
  margin-inline-start: 32px !important;
}

.margin_end_8 {
  margin-inline-end: 32px !important;
}

.margin_top_8 {
  margin-block-start: 32px !important;
}

.margin_bottom_8 {
  margin-block-end: 32px !important;
}

.margin_x_8 {
  margin-inline-end: 32px !important;
  margin-inline-start: 32px !important;
}

.margin_y_8 {
  margin-block-end: 32px !important;
  margin-block-start: 32px !important;
}

.padding_full_8 {
  padding: 32px !important;
}

.padding_start_8 {
  padding-inline-start: 32px !important;
}

.padding_end_8 {
  padding-inline-end: 32px !important;
}

.padding_top_8 {
  padding-block-start: 32px;
}

.padding_bottom_8 {
  padding-block-end: 32px !important;
}

.padding_x_8 {
  padding-inline-end: 32px !important;
  padding-inline-start: 32px !important;
}

.padding_y_8 {
  padding-block-end: 32px !important;
  padding-block-start: 32px !important;
}

.border_radius_8 {
  border-radius: 32px;
  -o-border-radius: 32px;
  -moz-border-radius: 32px;
  -webkit-border-radius: 32px;
}

.margin_full_9 {
  margin: 36px !important;
}

.margin_start_9 {
  margin-inline-start: 36px !important;
}

.margin_end_9 {
  margin-inline-end: 36px !important;
}

.margin_top_9 {
  margin-block-start: 36px !important;
}

.margin_bottom_9 {
  margin-block-end: 36px !important;
}

.margin_x_9 {
  margin-inline-end: 36px !important;
  margin-inline-start: 36px !important;
}

.margin_y_9 {
  margin-block-end: 36px !important;
  margin-block-start: 36px !important;
}

.padding_full_9 {
  padding: 36px !important;
}

.padding_start_9 {
  padding-inline-start: 36px !important;
}

.padding_end_9 {
  padding-inline-end: 36px !important;
}

.padding_top_9 {
  padding-block-start: 36px;
}

.padding_bottom_9 {
  padding-block-end: 36px !important;
}

.padding_x_9 {
  padding-inline-end: 36px !important;
  padding-inline-start: 36px !important;
}

.padding_y_9 {
  padding-block-end: 36px !important;
  padding-block-start: 36px !important;
}

.border_radius_9 {
  border-radius: 36px;
  -o-border-radius: 36px;
  -moz-border-radius: 36px;
  -webkit-border-radius: 36px;
}

.margin_full_10 {
  margin: 40px !important;
}

.margin_start_10 {
  margin-inline-start: 40px !important;
}

.margin_end_10 {
  margin-inline-end: 40px !important;
}

.margin_top_10 {
  margin-block-start: 40px !important;
}

.margin_bottom_10 {
  margin-block-end: 40px !important;
}

.margin_x_10 {
  margin-inline-end: 40px !important;
  margin-inline-start: 40px !important;
}

.margin_y_10 {
  margin-block-end: 40px !important;
  margin-block-start: 40px !important;
}

.padding_full_10 {
  padding: 40px !important;
}

.padding_start_10 {
  padding-inline-start: 40px !important;
}

.padding_end_10 {
  padding-inline-end: 40px !important;
}

.padding_top_10 {
  padding-block-start: 40px;
}

.padding_bottom_10 {
  padding-block-end: 40px !important;
}

.padding_x_10 {
  padding-inline-end: 40px !important;
  padding-inline-start: 40px !important;
}

.padding_y_10 {
  padding-block-end: 40px !important;
  padding-block-start: 40px !important;
}

.border_radius_10 {
  border-radius: 40px;
  -o-border-radius: 40px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
}

.margin_full_11 {
  margin: 44px !important;
}

.margin_start_11 {
  margin-inline-start: 44px !important;
}

.margin_end_11 {
  margin-inline-end: 44px !important;
}

.margin_top_11 {
  margin-block-start: 44px !important;
}

.margin_bottom_11 {
  margin-block-end: 44px !important;
}

.margin_x_11 {
  margin-inline-end: 44px !important;
  margin-inline-start: 44px !important;
}

.margin_y_11 {
  margin-block-end: 44px !important;
  margin-block-start: 44px !important;
}

.padding_full_11 {
  padding: 44px !important;
}

.padding_start_11 {
  padding-inline-start: 44px !important;
}

.padding_end_11 {
  padding-inline-end: 44px !important;
}

.padding_top_11 {
  padding-block-start: 44px;
}

.padding_bottom_11 {
  padding-block-end: 44px !important;
}

.padding_x_11 {
  padding-inline-end: 44px !important;
  padding-inline-start: 44px !important;
}

.padding_y_11 {
  padding-block-end: 44px !important;
  padding-block-start: 44px !important;
}

.border_radius_11 {
  border-radius: 44px;
  -o-border-radius: 44px;
  -moz-border-radius: 44px;
  -webkit-border-radius: 44px;
}

.margin_full_12 {
  margin: 48px !important;
}

.margin_start_12 {
  margin-inline-start: 48px !important;
}

.margin_end_12 {
  margin-inline-end: 48px !important;
}

.margin_top_12 {
  margin-block-start: 48px !important;
}

.margin_bottom_12 {
  margin-block-end: 48px !important;
}

.margin_x_12 {
  margin-inline-end: 48px !important;
  margin-inline-start: 48px !important;
}

.margin_y_12 {
  margin-block-end: 48px !important;
  margin-block-start: 48px !important;
}

.padding_full_12 {
  padding: 48px !important;
}

.padding_start_12 {
  padding-inline-start: 48px !important;
}

.padding_end_12 {
  padding-inline-end: 48px !important;
}

.padding_top_12 {
  padding-block-start: 48px;
}

.padding_bottom_12 {
  padding-block-end: 48px !important;
}

.padding_x_12 {
  padding-inline-end: 48px !important;
  padding-inline-start: 48px !important;
}

.padding_y_12 {
  padding-block-end: 48px !important;
  padding-block-start: 48px !important;
}

.border_radius_12 {
  border-radius: 48px;
  -o-border-radius: 48px;
  -moz-border-radius: 48px;
  -webkit-border-radius: 48px;
}

.font {
  font-family: MontserratArabic !important;
}

.font_12 {
  font-size: 12px !important;
}

.font_14 {
  font-size: 14px !important;
}

.font_16 {
  font-size: 16px !important;
}

.font_18 {
  font-size: 18px !important;
}

.font_20 {
  font-size: 20px !important;
}

.font_22 {
  font-size: 22px !important;
}

.font_24 {
  font-size: 24px !important;
}

.font_26 {
  font-size: 26px !important;
}

.font_28 {
  font-size: 28px !important;
}

.font_30 {
  font-size: 30px !important;
}

.font_32 {
  font-size: 32px !important;
}

.font_34 {
  font-size: 34px !important;
}

.font_100 {
  font-weight: 100 !important;
}

.font_200 {
  font-weight: 200 !important;
}

.font_300 {
  font-weight: 300 !important;
}

.font_400 {
  font-weight: 400 !important;
}

.font_500 {
  font-weight: 500 !important;
}

.font_600 {
  font-weight: 600 !important;
}

.font_700 {
  font-weight: 700 !important;
}

.font_800 {
  font-weight: 800 !important;
}

.font_900 {
  font-weight: 900 !important;
}

.app_badge {
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  position: relative;
  padding: 10px 24px;
  border-radius: 40px;
  display: inline-block;
  color: #F79200;
  font-family: MontserratArabic;
}
.app_badge.default {
  font-size: 11px;
  font-weight: 400;
  padding: 8px 16px;
  line-height: normal;
}
.app_badge::after {
  inset: 0;
  content: "";
  z-index: 0;
  opacity: 8%;
  position: absolute;
  background-color: currentColor;
}

.app_btn {
  z-index: 1;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  min-height: 48px;
  line-height: 24px;
  position: relative;
  padding: 12px 24px;
  border: 1px solid currentColor;
  color: #ffffff !important;
  background-color: #F79200 !important;
}
.app_btn::before {
  top: 0;
  right: 0;
  left: 100%;
  z-index: -1;
  content: "";
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.15);
  transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
.app_btn:hover::before {
  left: 0px;
}
.app_btn.ltr {
  direction: ltr;
}
.app_btn.rtl {
  direction: rtl;
}
.app_btn .wrap_svg_icon {
  margin-inline-end: 4px;
}

.input_field {
  position: relative;
}
.input_field.has_icon input {
  padding-inline-end: 8px !important;
  padding-inline-start: 44px !important;
}
.input_field.is_valid input {
  border-color: #F79200 !important;
}
.input_field.is_valid .wrap_icon svg, .input_field.is_valid .wrap_icon svg path {
  stroke: #F79200 !important;
}
.input_field.is_valid .wrap_input_file label {
  border-color: #F79200 !important;
}
.input_field.is_valid .wrap_input_file label .upload_img_file path {
  stroke: #F79200;
}
.input_field.is_valid .wrap_input_file label .label_text {
  color: #F79200;
}
.input_field.in_valid input {
  border-color: var(--bs-danger) !important;
}
.input_field.in_valid .wrap_icon svg, .input_field.in_valid .wrap_icon svg path {
  stroke: var(--bs-danger) !important;
}
.input_field label {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 12px;
  letter-spacing: -0.25px;
  color: #1F2934;
}
.input_field input {
  direction: rtl;
}
.input_field input:not([type=file]) {
  width: 100%;
  padding: 14px;
  font-size: 12px;
  font-weight: 300;
  min-height: 48px;
  text-align: start;
  border-radius: 8px;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: #1F1F1F;
  background-color: #ffffff;
  border: 1px solid #E7E7E7;
}
.input_field input::placeholder {
  color: #B0B0B0;
}
.input_field input:focus-visible {
  outline: none !important;
}
.input_field #phoneNumber {
  border-radius: 0px 8px 8px 0px;
}
.input_field .wrap_input_file {
  position: relative;
}
.input_field .wrap_input_file input {
  inset: 0;
  opacity: 0;
}
.input_field .wrap_input_file label {
  width: 100%;
  height: 112px;
  padding: 10px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  text-align: center;
  background-color: #FAFAFA;
  border: 1px dashed #E7E7E7;
}
.input_field .wrap_input_file label .label_text {
  width: 90%;
  margin-top: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: #1F1F1F;
}
.input_field .wrap_icon {
  top: 50%;
  right: 16px;
  position: absolute;
  line-height: normal;
  transform: translate(0, -50%);
}
.input_field .error_msg {
  padding-inline-start: 16px;
}

.nav_bar {
  padding: 24px 0;
  top: -100px;
  transition: top 0.2s linear;
  -o-transition: top 0.2s linear;
  -moz-transition: top 0.2s linear;
  -webkit-transition: top 0.2s linear;
}
.nav_bar.fixed {
  top: 0;
  z-index: 10;
  width: 100%;
  position: fixed;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);
}
.nav_bar.show .navbar_toggle span:nth-of-type(2)::after, .nav_bar.show .navbar_toggle span:nth-of-type(2)::before {
  opacity: 1;
}
.nav_bar.show .navbar_toggle span:not(:nth-of-type(2)) {
  opacity: 0;
}
.nav_bar.show .side_menu {
  right: 0 !important;
}
.nav_bar .navbar_toggle {
  height: 45px;
  padding: 10px 5px;
  border-radius: 5px;
  border: 2px solid #F79200;
}
.nav_bar .navbar_toggle span {
  width: 40px;
  height: 3px;
  border-radius: 5px;
  background-color: #F79200;
  transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
.nav_bar .navbar_toggle span:nth-of-type(2) {
  position: relative;
}
.nav_bar .navbar_toggle span:nth-of-type(2)::after, .nav_bar .navbar_toggle span:nth-of-type(2)::before {
  top: -4px;
  right: 0px;
  content: "";
  opacity: 0;
  height: 3px;
  width: 20px;
  display: block;
  position: absolute;
  border-radius: 5px;
  background: #F79200;
  transform: rotate(25deg);
  -o-transform: rotate(25deg);
  -moz-transform: rotate(25deg);
  -webkit-transform: rotate(25deg);
  transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}
.nav_bar .navbar_toggle span:nth-of-type(2)::before {
  right: 0;
  top: auto;
  bottom: -4px;
  transform: rotate(-25deg);
  -o-transform: rotate(-25deg);
  -moz-transform: rotate(-25deg);
  -webkit-transform: rotate(-25deg);
}
.nav_bar .links li {
  font-size: 14px;
  font-weight: 300;
  transition: font-weight 0.1s linear;
  -o-transition: font-weight 0.1s linear;
  -moz-transition: font-weight 0.1s linear;
  -webkit-transition: font-weight 0.1s linear;
}
.nav_bar .links li:not(:last-of-type) {
  margin-inline-end: 20px;
}
.nav_bar .links li:hover a, .nav_bar .links li.active a {
  color: #F79200;
  text-shadow: 0px 0px 0px #F79200;
}
.nav_bar .links li a {
  padding: 15px 10px;
  text-decoration: none;
  color: #1F2934;
  transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
.nav_bar .pages_links a:first-of-type {
  margin-inline-end: 9px;
}
.nav_bar .pages_links a .sign_in {
  border: none;
  color: #1F2934 !important;
  background-color: #ffffff !important;
}
.nav_bar .pages_links a .sign_in:hover {
  color: #ffffff !important;
  background-color: #F79200 !important;
}

.home_page .wrap_header {
  width: 100%;
  margin: 0 auto;
  max-width: 643px;
}
.home_page .wrap_header h1 {
  margin-top: 80px;
}
.home_page .wrap_header .shipment_tracking {
  padding: 18px 25px;
  border-radius: 8px;
  background-color: #FAFAFA;
}
.home_page .wrap_header .shipment_tracking input {
  border: none;
  outline: none;
  padding: 10px 12px;
  background-color: transparent;
}
.home_page .wrap_header .shipment_tracking input::placeholder {
  color: #888888;
}
.home_page .wrap_header .shipment_tracking .app_btn {
  max-width: 108px;
}
.home_page .about_us {
  margin-bottom: 80px;
}
.home_page .about_us .wrap_right {
  max-width: 567px;
}
.home_page .about_us .wrap_right .app_btn {
  max-width: 113px;
}
.home_page .services {
  margin-bottom: 80px;
}
.home_page .services .wrap_service {
  max-width: 340px;
}
.home_page .join_us {
  margin-bottom: -210px;
}
.home_page .join_us .wrap {
  padding: 80px 12px;
  position: relative;
  background-color: #F79200;
  border-radius: 24px;
  -o-border-radius: 24px;
  -moz-border-radius: 24px;
  -webkit-border-radius: 24px;
}
.home_page .join_us .wrap::before {
  right: 0;
  top: -7px;
  content: "";
  position: absolute;
  width: 265.73px;
  height: 255.61px;
  background: url(/src/assets//img/stai_arrow_right.svg) no-repeat top right;
}
.home_page .join_us .wrap::after {
  left: 0;
  bottom: -7px;
  content: "";
  position: absolute;
  width: 265.73px;
  height: 255.61px;
  background: url(/src/assets//img/stai_arrow_left.svg) no-repeat bottom left;
}
.home_page .join_us .wrap_content {
  margin: 0 auto;
  max-width: 652px;
}
.home_page .join_us .wrap_content .app_btn {
  max-width: 158px;
  color: #F79200 !important;
  background-color: #ffffff !important;
}
.home_page .contact_us {
  padding-top: 290px !important;
}

.register_trader_page {
  min-height: 100vh;
}
.register_trader_page .header_content {
  padding-top: 22px;
  width: calc(100% - 490px);
}
.register_trader_page .register_content {
  width: calc(100% - 490px);
}
.register_trader_page .register_content .custom_container {
  padding-bottom: 65px;
}
.register_trader_page .register_content .stepper {
  padding: 11px 12px;
  background-color: #ffffff;
  box-shadow: 0px 5px 24px 0px rgba(150, 150, 150, 0.08);
  -o-box-shadow: 0px 5px 24px 0px rgba(150, 150, 150, 0.08);
  -moz-box-shadow: 0px 5px 24px 0px rgba(150, 150, 150, 0.08);
  -webkit-box-shadow: 0px 5px 24px 0px rgba(150, 150, 150, 0.08);
}
.register_trader_page .register_content .stepper ul {
  margin: 0 auto;
  position: relative;
  width: calc(100% - 115px);
}
.register_trader_page .register_content .stepper ul li {
  opacity: 0.5;
  padding: 11px 0;
  transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  -moz-transition: opacity 0.1s linear;
  -webkit-transition: opacity 0.1s linear;
}
.register_trader_page .register_content .stepper ul li.active {
  opacity: 1;
}
.register_trader_page .register_content .stepper ul li .wrap_start {
  width: 40px;
  height: 40px;
  background-color: rgba(18, 177, 24, 0.08);
}
.register_trader_page .register_content .stepper_content .step {
  display: none;
}
.register_trader_page .register_content .stepper_content .step.active {
  display: block !important;
}
.register_trader_page .register_content .stepper_content .step .wrap_form {
  padding: 40px 50px;
  background-color: #ffffff;
  border: 1px solid #E7E7E7;
}
.register_trader_page .register_content .stepper_content .step .wrap_form .wrap_radio input[type=radio]:checked + label {
  color: #ffffff;
  background-color: #F79200;
}
.register_trader_page .register_content .stepper_content .step .wrap_form .wrap_radio label {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  min-width: 74px;
  min-height: 40px;
  padding: 8px 10px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.25px;
  color: #888888;
  background-color: #F0F0F0;
  border-radius: 8px;
  -o-border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}
.register_trader_page .register_content .stepper_content .step .wrap_stepper_btns .app_btn {
  width: auto;
}
.register_trader_page .register_content .stepper_content .step .wrap_stepper_btns .app_btn.previous {
  border-color: transparent !important;
  background-color: transparent !important;
  color: #888888 !important;
}
.register_trader_page .panner {
  width: 100%;
  padding: 40px;
  max-width: 490px;
  min-height: 100vh;
  margin-top: -108px;
  background: url(/src/assets//img/stai_arrow_left_x1.svg) no-repeat bottom right #F79200;
  background-size: auto;
}
.register_trader_page .panner .wrap_content {
  height: 50%;
  max-width: 362px;
}
.register_trader_page .modal .wrap_content {
  width: 100%;
  max-width: 502px;
  padding: 64px 15px;
  background-color: #ffffff;
  transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
}
.register_trader_page .modal .wrap_content .wrap_msg {
  width: 100%;
  max-width: 456px;
}
.register_trader_page .modal .wrap_content .app_btn {
  max-width: 366px;
}

.shipment_tracking_page .wrap_header {
  width: 100%;
  margin: 80px auto;
  max-width: 643px;
}
.shipment_tracking_page .wrap_header .shipment_tracking {
  padding: 18px 25px;
  border-radius: 8px;
  background-color: #FAFAFA;
}
.shipment_tracking_page .wrap_header .shipment_tracking input {
  border: none;
  outline: none;
  padding: 10px 12px;
  background-color: transparent;
}
.shipment_tracking_page .wrap_header .shipment_tracking input::placeholder {
  color: #888888;
}
.shipment_tracking_page .wrap_header .shipment_tracking .app_btn {
  max-width: 108px;
}
.shipment_tracking_page .wrap_shipment_tracking_details {
  padding: 50px 70px;
  margin-bottom: 80px;
  border: 1px solid #F4F4F4;
  background-color: #ffffff;
}
.shipment_tracking_page .wrap_shipment_tracking_details .wrap_top {
  margin: 0 auto;
  max-width: 425px;
}
.shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details {
  border: 1px solid #F0F0F0;
}
.shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details h4 {
  padding: 19px 30px;
  background-color: #F5F5F5;
}
.shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details ul {
  padding: 30px;
}
.shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details.shipment_info {
  width: 100%;
  max-width: 344px;
}
.shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details.shipment_time_line {
  width: 100%;
  max-width: 549px;
}
.shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details.shipment_time_line ul li {
  z-index: 1;
  position: relative;
}
.shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details.shipment_time_line ul li:last-of-type .wrap_progress::before {
  top: 25px;
  content: "";
  width: 32px;
  height: 100%;
  z-index: -1;
  position: absolute;
  background-color: #ffffff;
}
.shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details.shipment_time_line ul li.active .wrap_progress::after {
  background-color: #F79200;
}
.shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details.shipment_time_line ul li.active .wrap_progress .progress_dot {
  background-color: #F79200;
}
.shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details.shipment_time_line ul li .wrap_progress {
  width: 32px;
  height: 32px;
  margin: 0 16px;
  border-radius: 60px;
  background-color: #F5F5F5;
}
.shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details.shipment_time_line ul li .wrap_progress::after {
  top: 25px;
  width: 2px;
  z-index: -2;
  height: 100%;
  content: "";
  position: absolute;
  background: #F0F0F0;
}
.shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details.shipment_time_line ul li .wrap_progress .progress_dot {
  width: 16px;
  height: 16px;
  border-radius: 60px;
  border: 1px solid #ffffff;
  background-color: #B0B0B0;
}

.contact_us {
  padding: 80px 0;
  background-color: #010101;
}
.contact_us .wrap_start ul li a {
  background-color: rgba(255, 255, 255, 0.08);
}
.contact_us .wrap_end {
  padding: 60px;
  background: url(/src/assets//img/contact_us_bg.png) no-repeat left center rgba(255, 255, 255, 0.04);
  background-size: cover;
}
.contact_us .wrap_end form input, .contact_us .wrap_end form textarea, .contact_us .wrap_end form select {
  width: 100%;
  border: none;
  padding: 12px 16px;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
}
.contact_us .wrap_end form input::placeholder, .contact_us .wrap_end form textarea::placeholder, .contact_us .wrap_end form select::placeholder {
  color: #B0B0B0;
}
.contact_us .wrap_end form select option {
  font-size: 16px;
  color: #1F1F1F;
}
.contact_us .wrap_end form textarea {
  min-height: 110px;
}
.contact_us .wrap_end form .app_btn {
  border-color: #F79200;
}

footer .wrap_top {
  padding-top: 56px;
  padding-bottom: 65px;
}
footer .wrap_top .links li {
  font-size: 14px;
  font-weight: 300;
  transition: font-weight 0.1s linear;
  -o-transition: font-weight 0.1s linear;
  -moz-transition: font-weight 0.1s linear;
  -webkit-transition: font-weight 0.1s linear;
}
footer .wrap_top .links li:not(:last-of-type) {
  margin-inline-end: 20px;
}
footer .wrap_top .links li:hover a, footer .wrap_top .links li.active a {
  color: #F79200;
  text-shadow: 0px 0px 0px #F79200;
}
footer .wrap_top .links li a {
  padding: 15px 10px;
  text-decoration: none;
  color: #1F2934;
  transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
footer .wrap_top .media li {
  padding: 1px;
  border: 3px solid transparent;
  transition: border-color 0.1s linear;
  -o-transition: border-color 0.1s linear;
  -moz-transition: border-color 0.1s linear;
  -webkit-transition: border-color 0.1s linear;
}
footer .wrap_top .media li:hover {
  border-color: #F79200;
}
footer .wrap_top .media li a {
  width: 60px;
  height: 60px;
  background-color: #F79200;
}
footer .wrap_bottom {
  padding: 18px 0;
  background-color: #FAFAFA;
}

@media screen and (max-width: 575.98px) {
  .home_page .wrap_header .shipment_tracking, .shipment_tracking_page .wrap_header .shipment_tracking {
    padding: 18px 10px;
  }
  .home_page .wrap_header .shipment_tracking .wrap_right img, .shipment_tracking_page .wrap_header .shipment_tracking .wrap_right img {
    width: 20px;
    height: auto;
  }
  .home_page .wrap_header .shipment_tracking .wrap_right input, .shipment_tracking_page .wrap_header .shipment_tracking .wrap_right input {
    font-size: 14px !important;
  }
  .home_page .wrap_header .shipment_tracking .app_btn, .shipment_tracking_page .wrap_header .shipment_tracking .app_btn {
    max-width: none !important;
  }
  .home_page.home_page .join_us .wrap, .shipment_tracking_page.home_page .join_us .wrap {
    padding: 50px 12px;
  }
  .home_page.home_page .join_us .wrap::before, .home_page.home_page .join_us .wrap::after, .shipment_tracking_page.home_page .join_us .wrap::before, .shipment_tracking_page.home_page .join_us .wrap::after {
    width: 100px;
    height: 100px;
    background-size: contain;
  }
  .home_page.home_page .join_us .wrap .wrap_content, .shipment_tracking_page.home_page .join_us .wrap .wrap_content {
    max-width: 415px;
  }
  .home_page.home_page .join_us .wrap .wrap_content h2, .shipment_tracking_page.home_page .join_us .wrap .wrap_content h2 {
    font-size: 24px !important;
  }
  .register_trader_page .stepper ul {
    width: auto !important;
  }
  .register_trader_page .stepper_content .wrap_form {
    padding: 40px 15px !important;
  }
}
@media screen and (max-width: 991.98px) {
  .home_page .about_us .wrap_left {
    width: 100%;
    text-align: end;
  }
  .shipment_tracking_page .wrap_shipment_tracking_details {
    padding: 50px 12px !important;
  }
  .shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details {
    max-width: none !important;
  }
  .shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details h4 {
    width: 100%;
    text-align: center;
  }
  .shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details h4, .shipment_tracking_page .wrap_shipment_tracking_details .wrap_box_details ul {
    padding: 30px 12px !important;
  }
  .register_trader_page .header_content {
    padding-bottom: 22px;
    width: 100%;
  }
  .register_trader_page .header_content .wrap-top {
    margin-block-end: 0px !important;
  }
  .register_trader_page .register_content {
    width: 100%;
    max-width: none !important;
  }
  .register_trader_page .panner {
    width: 100%;
    margin-top: 0;
    text-align: center;
    margin-block-end: 36px;
    max-width: none !important;
    min-height: auto !important;
    background-size: contain !important;
  }
  .register_trader_page .panner .wrap_content {
    margin: 0 auto;
    max-width: none !important;
    height: auto !important;
  }
}
@media screen and (max-width: 575.98px) {
  footer .links li:not(:last-of-type) {
    margin-inline-end: 5px !important;
  }
}
@media screen and (max-width: 991.98px) {
  .nav_bar .side_menu {
    top: 0;
    bottom: 0;
    width: 100%;
    right: -100%;
    z-index: 100;
    padding: 24px;
    max-width: 300px;
    position: fixed;
    background-color: #ffffff;
    border-radius: 15px 0 0 15px;
    -o-border-radius: 15px 0 0 15px;
    -moz-border-radius: 15px 0 0 15px;
    -webkit-border-radius: 15px 0 0 15px;
    transition: right 0.2s linear;
    -o-transition: right 0.2s linear;
    -moz-transition: right 0.2s linear;
    -webkit-transition: right 0.2s linear;
    box-shadow: 8px 0px 10px #000000;
    -o-box-shadow: 8px 0px 10px #000000;
    -moz-box-shadow: 8px 0px 10px #000000;
    -webkit-box-shadow: 8px 0px 10px #000000;
  }
  .nav_bar .side_menu .links {
    margin-top: 40px;
  }
  .nav_bar .side_menu .links li {
    margin-inline-end: 0px;
  }
  .nav_bar .side_menu .links li:not(:last-of-type) {
    margin-bottom: 20px;
  }
  .nav_bar .side_menu .links li:hover a, .nav_bar .side_menu .links li.active a {
    color: #ffffff;
    background-color: #F79200;
    border-radius: 5px;
    -o-border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
  }
  .nav_bar .side_menu .links li a {
    display: block;
    padding-inline-end: 20px;
    padding-inline-start: 20px;
  }
  .nav_bar .side_menu .pages_links {
    margin-top: 30px;
  }
  .nav_bar .side_menu .pages_links a {
    display: block;
  }
  .nav_bar .side_menu .pages_links a:first-of-type {
    margin-inline-end: 0px;
  }
  .nav_bar .side_menu .pages_links a .sign_in {
    border: 1px solid #F79200;
  }
}
.loading_screen {
  inset: 0;
  padding: 15px;
  z-index: 1000;
  position: fixed;
  background-color: #ffffff;
}
.loading_screen .wrap_content {
  width: 100%;
  position: relative;
  overflow: hidden;
  max-width: 500px;
  min-height: 500px;
  animation: scaling 2s 0.7s infinite;
}
.loading_screen .wrap_content .wrap_part_1 {
  position: relative;
  animation: comingFomLeft 0.5s linear;
}
.loading_screen .wrap_content .wrap_part_2 {
  position: relative;
  animation: comingFomRight 0.5s linear;
}

@keyframes comingFomLeft {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}
@keyframes comingFomRight {
  0% {
    right: -100%;
  }
  100% {
    right: 0%;
  }
}
@keyframes scaling {
  0% {
    scale: 1;
  }
  30% {
    scale: 1.3;
  }
  60% {
    scale: 0.5;
  }
  100% {
    scale: 1;
  }
}
/* // import globle types & style fonts */
@font-face {
  font-family: "MontserratArabic";
  src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-Thin.otf") format("opentype");
  font-weight: 100; /* Thin */
}
@font-face {
  font-family: "MontserratArabic";
  src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-ExtraLight.otf") format("opentype");
  font-weight: 200; /* ExtraLight */
}
@font-face {
  font-family: "MontserratArabic";
  src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-Light.otf") format("opentype");
  font-weight: 300; /* Light */
}
@font-face {
  font-family: "MontserratArabic";
  src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-Regular.otf") format("opentype");
  font-weight: 400; /* Regular */
}
@font-face {
  font-family: "MontserratArabic";
  src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-Medium.otf") format("opentype");
  font-weight: 500; /* Medium */
}
@font-face {
  font-family: "MontserratArabic";
  src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-SemiBold.otf") format("opentype");
  font-weight: 600; /* SemiBold */
}
@font-face {
  font-family: "MontserratArabic";
  src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-Bold.otf") format("opentype");
  font-weight: 700; /* Bold */
}
@font-face {
  font-family: "MontserratArabic";
  src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-ExtraBold.otf") format("opentype");
  font-weight: 800; /* ExtraBold */
}
@font-face {
  font-family: "MontserratArabic";
  src: url("/src/assets/fonts/Montserrat-Arabic/Montserrat-Arabic-Black.otf") format("opentype");
  font-weight: 900; /* Black */
}
body {
  font-family: MontserratArabic;
}
body.navbar_fixed {
  padding-top: 100px;
}